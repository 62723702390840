import React, {Component} from 'react';
import './Documents.css';
import {withTranslation} from 'react-i18next';
import {Button, Toast} from "react-bootstrap";
import axios from "axios";
import {Redirect} from 'react-router-dom'
import DocumentPreview from "../DocumentPreview/DocumentPreview";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import NoDocsIcon from './../../assets/images/nodoc-icon.svg';
import {FadeLoader} from "react-spinners";
import {css} from "@emotion/core";
import environments from '../../config/environments';

const override = css`
    display: block;
    margin: 0 auto;
`;

class Documents extends Component {
    constructor(props) {
        super(props);
        this.state = {
            oAuthToken: sessionStorage.getItem('oauthTokenB64'),
            emailid: sessionStorage.getItem('emailid'),
            documents: [],
            renderedImages: [],
            selectedDoc: null,
            viewDocument: false,
            loading: false,
            showError: false,
            errorData: '',
            errorType: '',
            redirectToLoading: false,
            sessionExpired: false,
            renderedDoc: {
                pages: [],
                totalPages: 0,
                nextPage: 1
            },
            loadingMorePages: false, 
        };

        this.renderDocumentOnDemand = this.renderDocumentOnDemand.bind(this);
    }

    redirectToLoading() {
        this.setState({redirectToLoading: true})
    }

    getDocuments() {
        const {emailid, oAuthToken} = this.state;
        const _this = this;
        _this.setState({loading: true});

        var config = {
            headers: {
                'Authorization': `Bearer ${oAuthToken}`,
                'Content-Type': 'application/json;charset=utf-8',
                'Accept': 'application/json;charset=utf-8',
            }
        };

        axios.get(environments.vidUrlApiPriv + '/edeliveries/' + emailid + '/documents', config)
            .then(res => {
                const documents = res.data;
                if (documents.length === 1) {
                    _this.setState({selectedDoc: documents[0]}, () => this.renderDocumentOnDemand());
                }
                if (documents.length > 1) {
                    _this.setState({documents: documents, loading: false});
                }
            })
            .catch(function (error) {
                if (error?.response?.status === 401) {
                    _this.setState({sessionExpired: true, loading: false});
                } else if (error?.response?.status === 409) {
                    /**Document not available */
                    _this.setState({loading: false});
                }
                else {
                    _this.setState({
                        errorType:error?.response?.status,
                        errorData:error?.response?.data,
                        showError: true,
                        loading: false
                    });
                }
            });
    }

    viewSelectedDocument(x) {
        /**esta función debería seleccionar el documento deseado de la lista y mostrar el preview */
        this.setState({selectedDoc: x, loading: true}, () => this.renderDocumentOnDemand())
    }

    renderDocumentOnDemand() {
        const _this = this;
        const { selectedDoc, renderedDoc, loadingMorePages, emailid, oAuthToken} = this.state;
        const { pages, nextPage } = renderedDoc;

        if (loadingMorePages || nextPage === -1) return;

        if (pages.length > 0) this.setState({ loadingMorePages: true });

        var config = {
            headers: {
                'Authorization': `Bearer ${oAuthToken}`,
                'Content-Type': 'application/json;charset=utf-8',
                'Accept': 'application/json;charset=utf-8',
            }
        };

        axios.get(`${environments.vidUrlApiPriv}/edeliveries/${emailid}/documents/${selectedDoc.GUID}/render/${nextPage}`, config)
            .then(response => {
                const data = response.data;
                const pagesData = pages.concat(data.DocPageRendered);
                const totalPdfPagesNumber = data.NumberOfPages;
                const nextPageToRender = data.NextPage;
                
                _this.setState({
                    loadingMorePages: false,
                    loading: false,
                    viewDocument: true,
                    renderedDoc: {
                        pages: pagesData,
                        nextPage: nextPageToRender,
                        totalPages: totalPdfPagesNumber
                    }
                });
            })
            .catch(function (error) {
                if (error?.response?.status === 401) {
                    _this.setState({sessionExpired: true});
                } else {
                    _this.setState({
                        errorType: error?.response?.status,
                        errorData: error?.response?.data,
                        showError: true
                    });
                }
            });
    }

    renderDocument() {
        const {emailid, oAuthToken, selectedDoc} = this.state;
        const _this = this;

        var config = {
            headers: {
                'Authorization': `Bearer ${oAuthToken}`,
                'Content-Type': 'application/json;charset=utf-8',
                'Accept': 'application/json;charset=utf-8',
            }
        };

        axios.get(environments.vidUrlApiPriv + '/edeliveries/' + emailid + '/documents/' + selectedDoc.GUID + '/render', config)
            .then(res => {
                let renderedImages = res.data.DocPageRendered.map(renderedImage => {
                    return <img
                        alt="renderedImage"
                        src={`data:image/jpeg;base64,${renderedImage}`}
                        className="pdf-images"
                    />
                });
                _this.setState({renderedImages: renderedImages, renderedDoc: res.data.DocPageRendered, viewDocument: true, loading: false});
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    _this.setState({sessionExpired: true});
                } else {
                    _this.setState({
                        errorType: error.response.status,
                        errorData: error.response.data,
                        showError: true
                    });
                }
            });

    }

    viewDocumentHandler() {
        this.setState({
            viewDocument: false
        });
        this.getDocuments();
    }

    render() {
        const {t} = this.props;
        const {showError, errorType, errorData, sessionExpired, redirectToLoading, documents, viewDocument, loading, emailid, oAuthToken, renderedDoc, loadingMorePages, selectedDoc} = this.state;

        if (redirectToLoading) {
            return <Redirect to={{
                pathname: '/'
            }}
            />;
        }


        return (
            <div>
                <Header/>
                <div className="documents-container">
                    {showError &&
                    <Toast
                        onClose={() => this.setState({showError: false})}
                        show={showError}
                        animation={true}
                        style={{
                            position: 'absolute',
                            left: 0,
                            right: 0,
                            top: 0,
                            margin: '0 auto',
                            backgroundColor: 'rgba(255,255,255,1)',
                            border: 'none',
                            zIndex: 15
                        }}
                    >
                        <Toast.Header
                            closeButton={true}
                        >
                            <strong className="mr-auto">{t('general.error')} {errorType}</strong>
                        </Toast.Header>
                        <Toast.Body>{errorData}</Toast.Body>
                    </Toast>
                    }
                    {sessionExpired &&
                    <div className="toast-container">
                        <Toast
                            show={sessionExpired}
                            style={{
                                position: 'absolute',
                                left: 0,
                                right: 0,
                                margin: '0 auto',
                                backgroundColor: 'rgba(255,255,255,1)',
                                border: 'none',
                                zIndex: 10
                            }}
                        >
                            <Toast.Header
                                closeButton={false}
                            >
                                <strong className="mr-auto">{t('general.notification')}</strong>
                            </Toast.Header>
                            <Toast.Body>
                                <p>{t('general.session-expired')}</p>
                                <Button
                                    type="button"
                                    className="btn btn-primary"
                                    id="sign-all-docs"
                                    onClick={() => this.redirectToLoading()}
                                >
                                    {t('general.accept')}
                                </Button>
                            </Toast.Body>
                        </Toast>
                    </div>
                    }
                    <div>
                        {(loading) &&
                        <div className='sweet-loading-preview'>
                            <FadeLoader
                                css={override}
                                sizeUnit={"px"}
                                size={150}
                                color={'#00bf71'}
                                loading={true}
                            />
                        </div>
                        }
                        {
                            loadingMorePages && 
                                <div className="loading-more-container">
                                    <FadeLoader
                                        css={override}
                                        sizeUnit={"px"}
                                        size={150}
                                        color={'#00bf71'}
                                        loading={true}
                                    />
                                </div>
                        }
                        {(!loading) &&
                        <div>
                            {(documents.length === 0 && selectedDoc === null) &&
                            <div>
                                <div className="no-pending-container">
                                    <div className="icon-container">
                                        <img src={NoDocsIcon} className="no-docs-icon" alt="no-docs-icon" />
                                        <div id="no-docs-text-container">
                                            <h1 id="no-docs-title">{t('documents.no-docs-title')}</h1>
                                            <h2 id="no-docs-subtitle">{t('documents.no-docs-subtitle')}</h2>
                                        </div>
                                        <Button
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={() => this.redirectToLoading()}
                                        >
                                            {t('general.return')}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                            }
                            {(documents.length > 0 || selectedDoc !== null) &&
                            <div>
                                {((selectedDoc.length > 0 || viewDocument) && renderedDoc.pages.length > 0) &&
                                <DocumentPreview
                                    oAuthToken={oAuthToken}
                                    emailid={emailid}
                                    renderedDoc={renderedDoc}
                                    renderedImages={renderedDoc.pages}
                                    selectedDoc={selectedDoc}
                                    documents={documents}
                                    viewDocumentHandler={this.viewDocumentHandler}
                                    renderDocumentOnDemand={this.renderDocumentOnDemand}
                                />
                                }
                                {(!viewDocument && documents.length > 1) &&
                                <div className="documents-list-container">
                                    <div className="documents-list-header">
                                        <h1>{t('documents.list-title')}</h1>
                                    </div>
                                    <div className="documents-list-body">

                                    </div>
                                </div>
                                }
                            </div>
                            }
                        </div>
                        }
                    </div>
                </div>
                {!viewDocument &&
                <Footer/>
                }
            </div>
        );
    }

    componentDidMount() {
        this.getDocuments();
    }
}

export default withTranslation()(Documents);
