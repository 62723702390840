import React, {Component} from 'react';
import './App.css';
import { Switch, Route, BrowserRouter, Redirect } from 'react-router-dom'
import SendSMS from '../components/SendSMS/SendSMS'
import SendCustomSMS from '../components/SendCustomSMS/SendCustomSMS'
import Loading from '../components/Loading/Loading'
import Documents from '../components/Documents/Documents'
import ProcessFinished from '../components/ProcessFinished/ProcessFinished'
import {Col, Container, Row} from "react-bootstrap";

class App extends Component {
    render() {

        const PrivateRoute = ({ component: Component, path: Path,  ...rest }) => (
            <Route {...rest} render={(props) => (
                (sessionStorage.getItem('oauthTokenB64') !== null &&
                 sessionStorage.getItem('isTokenValid') === 'true')
                    ? <Component {...props} />
                    : <Redirect to='/' />
            )} />
        );

        return (
            <div className="App">
              <Container>
                <Row>
                  <Col>
                    <BrowserRouter>
                      <Switch>
                        <Route
                            exact path='/'
                            component={Loading}
                        />
                        <PrivateRoute path='/sms' component={SendSMS} />
                        <PrivateRoute path='/custom-sms' component={SendCustomSMS} />
                        <PrivateRoute path='/documents' component={Documents} />
                        <PrivateRoute path='/process-finished' component={ProcessFinished} />
                      </Switch>
                    </BrowserRouter>
                  </Col>
                </Row>
              </Container>
            </div>
        )
    }
}

export default App;
