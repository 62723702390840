import React, {Component} from 'react';
import './DocumentPreview.css';
import {withTranslation} from 'react-i18next';
import {Button, Toast} from "react-bootstrap";
import axios from "axios";
import {Redirect} from 'react-router-dom'
import {MdUndo} from "react-icons/md";
import environments from '../../config/environments';

class DocumentPreview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            showError: false,
            errorData: '',
            errorType: '',
            sessionExpired: false,
            redirectToLoading: false,
            actualPage: 1,
            selectedDocBase64: null,
            processFinished: false
        };
        this.actualPage = this.actualPage.bind(this);
    }

    redirectToLoading(){
        this.setState({redirectToLoading: true})
    }

    actualPage() {
        const { renderedDoc, renderDocumentOnDemand } = this.props;

        const renderedPages = renderedDoc.pages.length;

        var container_height = document.getElementsByClassName("pdf-container")[0].offsetHeight;
        var image_height = document.getElementsByClassName("pdf-images")[0].offsetHeight;
        var scroll_height = image_height * renderedPages;

        /**Scroll position*/
        var scroll_position = document.getElementsByClassName("pdf-container")[0].scrollTop;
        var scroll_max_value = scroll_height - container_height;
        var scroll_percentage = Math.abs((scroll_position/scroll_max_value)*100);

        var page_ratio = 100/renderedPages; /**6,25  */
        var actual_page = scroll_percentage/page_ratio; /**Por cada 6,25 saldrá 1 */
        var max_page_ratio = 100/page_ratio; /**Número de páginas en total */

        /**Sumamos 1 de base para no mostrar el valor 0 */
        var actual_page_absolute = 1 + Math.floor(actual_page)

        /**Comprobamos que no llegue al máximo para no mostrar el máximo de páginas +1 y le adjudicamos el valor máximo */
        if (actual_page_absolute >= max_page_ratio) {
            actual_page_absolute = renderedPages;
        }

        if (actual_page_absolute >= renderedPages - 1) renderDocumentOnDemand();

        this.setState({actualPage: actual_page_absolute})
    }

    getDocumentInfo() {
        const {emailid, oAuthToken, selectedDoc} = this.props;
        const _this = this;

        var config = {
            headers: {
                'Authorization': `Bearer ${oAuthToken}`,
                'Content-Type': 'application/json;charset=utf-8',
                'Accept': 'application/json;charset=utf-8',
            }
        };

        axios.get(environments.vidUrlApiPriv + '/edeliveries/' + emailid + '/documents/' + selectedDoc.GUID, config)
            .then(res => {
                _this.setState({selectedDocBase64: res.data.DocContent}, () => this.downloadDocument());
            })
            .catch(function (error) {
                if (error.status === 401) {
                    _this.setState({sessionExpired: true});
                } else {
                    _this.setState({
                        errorType: error.status,
                        errorData: error.data,
                        showError: true,
                        loading: false
                    });
                }
            });

    }

    downloadDocument() {
        const {selectedDocBase64} = this.state;
        const {selectedDoc} = this.props;
        const _this = this;
        const fileName = selectedDoc.FileName;

        if (window.navigator && window.navigator.msSaveOrOpenBlob) { // IE workaround
            var byteCharacters = atob(selectedDocBase64);
            var byteNumbers = new Array(byteCharacters.length);
            for (var i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            var byteArray = new Uint8Array(byteNumbers);
            var blob = new Blob([byteArray], {type: 'application/pdf'});
            window.navigator.msSaveOrOpenBlob(blob, fileName);
        }
        else{
          var newBlob = new Blob([this.base64ToArrayBuffer(selectedDocBase64)], { type: 'application/octet-stream' });
          var fileURL = window.URL.createObjectURL(newBlob);
          this.createDownloadElementAndClick(fileURL, fileName);
        }
        _this.setState({processFinished: true});
    }

  createDownloadElementAndClick(fileURL, fileName) {
    var anchorElement = window.document.createElement('a');
    anchorElement.href = fileURL;
    anchorElement.target = '_self';
    anchorElement.download = fileName;
    var event = window.document.createEvent("MouseEvents");
    event.initEvent("click", true, false);
    anchorElement.dispatchEvent(event);
  }

  base64ToArrayBuffer(data) {
    var bString = window.atob(data);
    var bLength = bString.length;
    var bytes = new Uint8Array(bLength);
    for (var i = 0; i < bLength; i++) {
      var ascii = bString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }

    render() {
        const {t, selectedDoc, documents, renderedImages, renderedDoc} = this.props;
        const {showError, errorType, errorData, sessionExpired, redirectToLoading, actualPage, processFinished} = this.state;

        const domImages = renderedImages.map((renderedImage, index) => {
            return <img
              key={index}
              alt="renderedImage"
              src={`data:image/jpeg;base64,${renderedImage}`}
              className="pdf-images"
              id={`pdf-page-${index + 1}`}
            />
          });

        console.log({ renderedImages, domImages })

        if (redirectToLoading) {
            return <Redirect to={{
                pathname: '/'
            }}
            />;
        }

        if (processFinished) {
            return <Redirect to={{
                pathname: '/process-finished'
            }}
            />;
        }


        return (
            <div>
                <div className="document-preview-container">
                    {showError &&
                    <Toast
                        onClose={() => this.setState({showError: false})}
                        show={showError}
                        animation={true}
                        style={{
                            position: 'absolute',
                            left: 0,
                            right: 0,
                            top: 0,
                            margin: '0 auto',
                            backgroundColor: 'rgba(255,255,255,1)',
                            border: 'none',
                            zIndex: 15
                        }}
                    >
                        <Toast.Header
                            closeButton={true}
                        >
                            <strong className="mr-auto">{t('general.error')} {errorType}</strong>
                        </Toast.Header>
                        <Toast.Body>{errorData}</Toast.Body>
                    </Toast>
                    }
                    {sessionExpired &&
                    <div className="toast-container">
                        <Toast
                            show={sessionExpired}
                            style={{
                                position: 'absolute',
                                left: 0,
                                right: 0,
                                margin: '0 auto',
                                backgroundColor: 'rgba(255,255,255,1)',
                                border: 'none',
                                zIndex: 10
                            }}
                        >
                            <Toast.Header
                                closeButton={false}
                            >
                                <strong className="mr-auto">{t('general.notification')}</strong>
                            </Toast.Header>
                            <Toast.Body>
                                <p>{t('general.session-expired')}</p>
                                <Button
                                    type="button"
                                    className="btn btn-primary"
                                    id="sign-all-docs"
                                    onClick={() => this.redirectToLoading()}
                                >
                                    {t('general.accept')}
                                </Button>
                            </Toast.Body>
                        </Toast>
                    </div>
                    }
                    {documents.length > 1 &&
                    <MdUndo className="back-button" onClick={this.props.viewDocumentHandler}/>
                    }
                    <div className="document-preview-header">
                        <h1 className="document-preview-title">{t('documents.document-preview-title')}</h1>
                        <div className="document-preview-subcontainer">
                            <h2 className="document-preview-name">{selectedDoc.FileName}</h2>
                            <span className="document-preview-count">{actualPage}/{renderedDoc.totalPages}</span>
                        </div>
                    </div>
                    <div className="pdf-container">
                        {domImages}
                    </div>
                </div>
                <div className="buttons-container">
                    <Button
                        type="button"
                        id="signModalButton"
                        className="btn btn-primary download-button"
                        onClick={() => this.getDocumentInfo()}
                    >
                        {t('general.download')}
                    </Button>
                </div>
            </div>
        );
    }

    componentDidMount() {
        document.getElementsByClassName('pdf-container')[0].addEventListener("scroll", this.actualPage);
    }
}

export default withTranslation()(DocumentPreview);