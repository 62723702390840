import React, {Component} from 'react';
import './Header.css';
import {Nav, Navbar} from 'react-bootstrap';
import {withTranslation} from 'react-i18next';



class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        return (
            <header className="App-header">
                <nav>
                    <div className="nav-container">
                        <img
                            src={require('../../assets/images/logo_validated.png')}
                            height="30"
                            width="auto"
                            className="logo"
                            alt="Validated ID logo"
                        />
                    </div>
                    <div className="nav-container">
                        <p id="logo_text">Always be yourself</p>
                    </div>
                </nav>
            </header>
        );
    }
}

export default withTranslation()(Header);