import React, { Component } from 'react';
import './SendCustomSMS.css';
import { withTranslation } from 'react-i18next';
import { Button, Form, InputGroup, Spinner, Toast } from "react-bootstrap";
import axios from "axios";
import { Redirect } from 'react-router-dom'
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import OtpIcon from './../../assets/images/otp-icon.svg';
import environments from '../../config/environments';

class SendCustomSMS extends Component {
    constructor(props) {
        super(props);
        this.state = {
            oAuthToken: sessionStorage.getItem('oauthTokenB64'),
            emailid: sessionStorage.getItem('emailid'),
            phoneNumber: "",
            otpInput: "",
            redirect: false,
            validatingOTP: false,
            showError: false,
            errorData: '',
            errorType: '',
            SMSresent: false,
            redirectToLoading: false,
            sessionExpired: false,
            invalidOTP: false,
            isNotValidPhoneNumber: false,
            showModalPhoneNumberValidation: false,
            smsSent: false,
            errorOTP: false,
            sendingSMS: false
        };
    }

    validateOTP() {
        const { otpInput } = this.state;

        this.setState({ validatingOTP: true, errorOTP: false });

        // The master OTP has 5 digits the rest of them have 6 digits
        if (otpInput.length < 5) {
            this.setState({ validatingOTP: false, errorOTP: true });
            return;
        }

        this.sendOTP();
    }

    sendOTP() {
        const { emailid, oAuthToken, otpInput } = this.state;
        const _this = this;

        axios({
            method: 'put',
            url: environments.vidUrlApiPriv + '/edeliveries/' + emailid + '/otpcode',
            data: {
                OTP: otpInput
            },
            async: true,
            dataType: "json",
            contentType: 'application/json',
            crossDomain: true,
            headers: {
                'Authorization': `Bearer ${oAuthToken}`,
                'Content-Type': 'application/json;charset=utf-8',
                'Accept': 'application/json;charset=utf-8',
            }
        })
            .then(res => {
                _this.setState({ redirect: true, validatingOTP: false });
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    _this.setState({ sessionExpired: true });
                } else {
                    _this.setState({
                        invalidOTP: true,
                        validatingOTP: false
                    });
                }
            });
    }

    sendSMSHelper() {
        const { emailid, oAuthToken, phoneNumber } = this.state;

        return new Promise((resolve, reject) => {
            axios({
                method: 'put',
                url: environments.vidUrlApiPriv + '/edeliveries/' + emailid + '/otpsms',
                data: {
                    "PhoneNumber": phoneNumber
                },
                async: true,
                dataType: "json",
                contentType: 'application/json',
                crossDomain: true,
                headers: {
                    'Authorization': `Bearer ${oAuthToken}`,
                    'Content-Type': 'application/json;charset=utf-8',
                    'Accept': 'application/json;charset=utf-8',
                }
            })
                .then(res => {
                    resolve(res.status);
                })
                .catch(function (error) {
                    reject(error.response);
                });
        });
    }

    setErrorState(error) {
        if (error.status === 401) {
            this.setState({ sessionExpired: true });
            return;
        }

        this.setState({
            errorType: error.status,
            errorData: error.data,
            showError: true
        });
    }

    sendSMS() {
        let _this = this;

        _this.setState({ sendingSMS: true });

        this.sendSMSHelper()
            .then(() => {
                _this.setState({ smsSent: true });
            })
            .catch((error) => {
                _this.setErrorState(error);
            })
            .then(() => {
                _this.setState({ showModalPhoneNumberValidation: false, sendingSMS: false });
            });
    }

    resendSMS() {
        const _this = this;

        this.sendSMSHelper()
            .then(() => {
                _this.setState({ SMSresent: true });
            })
            .catch((error) => {
                _this.setErrorState(error);
            });
    }

    redirectToLoading() {
        this.setState({ redirectToLoading: true })
    }

    isValidPhoneNumber(phoneNumber) {
        let isValid = false;

        if (phoneNumber.indexOf("+") === 0) {
            let number = phoneNumber.substring(1, phoneNumber.length);
            if (!isNaN(Number(number)))
                isValid = true;
        }

        return isValid;
    }

    validatePhoneNumber() {
        if (!this.isValidPhoneNumber(this.state.phoneNumber)) {
            this.setState({ isNotValidPhoneNumber: true })
            return;
        }

        this.setState({ isNotValidPhoneNumber: false, showModalPhoneNumberValidation: true })
    }

    handlePhoneNumberChange = (e) => {
        this.setState({ phoneNumber: e.target.value, isNotValidPhoneNumber: false })
    };

    render() {
        const { t } = this.props;
        const { redirect, redirectToLoading, validatingOTP, SMSresent, showError, errorType, errorData, 
                sessionExpired, invalidOTP, showModalPhoneNumberValidation, phoneNumber, 
                isNotValidPhoneNumber, errorOTP, smsSent, sendingSMS 
              } = this.state;

        if (redirect) {
            return <Redirect to={{
                pathname: '/documents'
            }}
            />;
        }

        if (redirectToLoading) {
            return <Redirect to={{
                pathname: '/'
            }}
            />;
        }

        return (
            <div>
                <Header />
                <div className="send-sms-container">
                    {SMSresent &&
                        <Toast
                            onClose={() => this.setState({ SMSresent: false })}
                            show={SMSresent}
                            delay={3000}
                            autohide
                            style={{
                                position: 'absolute',
                                left: 0,
                                right: 0,
                                top: 0,
                                margin: '0 auto',
                                backgroundColor: 'rgba(255,255,255,1)',
                                border: 'none',
                                zIndex: 15
                            }}
                        >
                            <Toast.Header
                                closeButton={false}
                            >
                                <strong className="mr-auto">{t('general.notification')}</strong>
                            </Toast.Header>
                            <Toast.Body>{t('sms.otp-resent')}</Toast.Body>
                        </Toast>
                    }
                    {showError &&
                        <Toast
                            onClose={() => this.setState({ showError: false })}
                            show={showError}
                            animation={true}
                            style={{
                                position: 'absolute',
                                left: 0,
                                right: 0,
                                top: 0,
                                margin: '0 auto',
                                backgroundColor: 'rgba(255,255,255,1)',
                                border: 'none',
                                zIndex: 15
                            }}
                        >
                            <Toast.Header
                                closeButton={true}
                            >
                                <strong className="mr-auto">{t('general.error')} {errorType}</strong>
                            </Toast.Header>
                            <Toast.Body>{errorData}</Toast.Body>
                        </Toast>
                    }
                    {sessionExpired &&
                        <div className="toast-container">
                            <Toast
                                show={sessionExpired}
                                style={{
                                    position: 'absolute',
                                    left: 0,
                                    right: 0,
                                    margin: '0 auto',
                                    backgroundColor: 'rgba(255,255,255,1)',
                                    border: 'none',
                                    zIndex: 10
                                }}
                            >
                                <Toast.Header
                                    closeButton={false}
                                >
                                    <strong className="mr-auto">{t('general.notification')}</strong>
                                </Toast.Header>
                                <Toast.Body>
                                    <p>{t('general.session-expired')}</p>
                                    <Button
                                        type="button"
                                        className="btn btn-primary"
                                        id="sign-all-docs"
                                        onClick={() => this.redirectToLoading()}
                                    >
                                        {t('general.accept')}
                                    </Button>
                                </Toast.Body>
                            </Toast>
                        </div>
                    }
                    {showModalPhoneNumberValidation &&
                        <Toast
                            show={showModalPhoneNumberValidation}
                            onClose={() => this.setState({ showModalPhoneNumberValidation: false })}
                            style={{
                                position: 'absolute',
                                left: 0,
                                right: 0,
                                margin: '0 auto',
                                backgroundColor: 'rgba(255,255,255,1)',
                                border: 'none',
                                zIndex: 10
                            }}
                        >
                            <Toast.Header
                                closeButton={true}
                            >
                                <strong className="mr-auto">{t('custom-sms.phone-validation-title')}</strong>
                            </Toast.Header>
                            <Toast.Body>
                                <p>{t('custom-sms.phone-validation-description')} <strong>{phoneNumber}</strong></p>
                                <p>{t('custom-sms.phone-validation-cancel')}</p>
                                {sendingSMS &&
                                    <Spinner animation="border" role="status" className="spinner-centered">
                                        <span className="sr-only">{t('general.loading')}</span>
                                    </Spinner>
                                }
                                {!sendingSMS &&
                                    <>
                                        <Button
                                            type="button"
                                            className="btn reject mr-3"
                                            id="cancel-validation"
                                            onClick={() => this.setState({ showModalPhoneNumberValidation: false })}
                                        >
                                            {t('general.cancel')}
                                        </Button>
                                        <Button
                                            type="button"
                                            className="btn btn-primary"
                                            id="phone-validation"
                                            onClick={() => this.sendSMS()}
                                        >
                                            {t('custom-sms.send')}
                                        </Button>
                                    </>
                                }
                            </Toast.Body>
                        </Toast>
                    }
                    <div className="send-sms-content">
                        <div className="send-sms-content-left">
                            <div className="send-sms-content-left-text">
                                <div className="flex-container">
                                    <img src={OtpIcon} className="otp-icon" alt="otp-icon" />
                                    <h1 className="content-header-title">{t('sms.title')}</h1>
                                </div>
                                <p className="content-middle-1">{t('custom-sms.step-1')}</p>

                                <InputGroup>

                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder={t('custom-sms.phone-number-placeholder')}
                                        aria-label="Phone number"
                                        aria-describedby="phone-number-button"
                                        autoFocus={true}
                                        value={phoneNumber}
                                        onChange={this.handlePhoneNumberChange}
                                        isInvalid={isNotValidPhoneNumber}
                                        disabled={showModalPhoneNumberValidation || showError || smsSent}
                                    />
                                    <InputGroup.Append>
                                        <Button
                                            id="phone-number-button"
                                            onClick={() => this.validatePhoneNumber()}
                                            disabled={phoneNumber === "" || smsSent}
                                        >
                                            {t('custom-sms.send')}
                                        </Button>
                                    </InputGroup.Append>
                                </InputGroup>

                                {isNotValidPhoneNumber &&
                                    <p className="error-msg">{t('custom-sms.error-not-valid-phone-number')}</p>
                                }

                                {/* Set OPT code section */}
                                <div>
                                    <p className="mt-3 content-middle-3">{t('sms.step-2')}</p>

                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <InputGroup.Text
                                                id="inputGroup-sizing-default">{t('sms.otp')}</InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <Form.Control
                                            required
                                            type="password"
                                            aria-label="Default"
                                            aria-describedby="inputGroup-sizing-default"
                                            value={this.state.otpInput}
                                            onChange={e => this.setState({ otpInput: e.target.value, invalidOTP: false })}
                                            isInvalid={invalidOTP}
                                            disabled={!smsSent}
                                        />
                                    </InputGroup>
                                    {invalidOTP &&
                                        <p className="error-msg">{t('sms.otp-invalid')}</p>
                                    }

                                    {errorOTP &&
                                        <p className="error-msg">{t('custom-sms.error-otp')}</p>
                                    }

                                    <div className="mt-3">
                                        {validatingOTP &&
                                            <Spinner animation="border" role="status" className="spinner-centered">
                                                <span className="sr-only">{t('general.loading')}</span>
                                            </Spinner>
                                        }
                                        {!validatingOTP &&
                                            <Button
                                                type="button"
                                                className="btn btn-primary send-sms-button action-button"
                                                onClick={() => this.validateOTP()}
                                                disabled={this.state.otpInput.length < 5}
                                            >
                                                {t('sms.view-document')}
                                            </Button>
                                        }
                                    </div>
                                    <div className="content-bottom">
                                        <p className="content-bottom-text">{t('sms.no-otp')}</p>
                                        <Button
                                            type="button"
                                            className="btn reject action-button-small"
                                            onClick={() => this.resendSMS()}
                                            disabled={!smsSent || validatingOTP}
                                        >
                                            {t('sms.new-otp')}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="send-sms-content-right"> </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

export default withTranslation()(SendCustomSMS);
