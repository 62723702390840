import React, {Component} from 'react';
import './ProcessFinished.css';
import {withTranslation} from 'react-i18next';
import Footer from "../Footer/Footer";


class ProcessFinished extends Component {
    constructor(props) {
        super(props);
        this.state = {
            oAuthToken: sessionStorage.getItem('oauthTokenB64'),
            emailid: sessionStorage.getItem('emailid'),
            SendDocToRecipient: sessionStorage.getItem('SendDocToRecipient'),
        };
    }

    render() {
        const {t} = this.props;
        const {SendDocToRecipient} = this.state;

        return (
            <div>
                <div className="process-finished-container">
                    <div className="process-finished-text-container">
                        <h1 id="process-finished-title">{t('process-finished.success')}</h1>
                        <h2 className="process-finished-subtitle">{t('process-finished.subtitle-1')}</h2>
                        {SendDocToRecipient === "true" &&
                            <h2 className="process-finished-subtitle">{t('process-finished.subtitle-2')}</h2>
                        }
                    </div>
                </div>
                <Footer/>
            </div>
        );
    }

    componentDidMount() {
        sessionStorage.setItem('isTokenValid', false)
    }
}

export default withTranslation()(ProcessFinished);
