import React, {Component} from 'react';
import './Loading.css';
import {withTranslation} from 'react-i18next';
import axios from "axios";
import {Redirect} from 'react-router-dom'
import {ProgressBar, Spinner} from "react-bootstrap";
import i18n from '../../config/i18n';
import Header from "../Header/Header";
import environments from '../../config/environments';

const ipLocation = require("iplocation");
const publicIp = require('public-ip');
const {detect} = require('detect-browser');
const browser = detect();

class Loading extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errorText: '',
            emailid: '',
            oAuthToken: '',
            clientIp: '',
            ipData: '',
            redirect: false,
            progressPercentage: 0,
            pocessText: '',
            loading: false,
            customSMS: false
        };
    }

    checkLanguage() {
        const {emailid, oAuthToken} = this.state;

        return new Promise(function (resolve, reject) {
            axios({
                method: 'get',
                url: environments.vidUrlApiPriv + '/edeliveries/' + emailid,
                data: '',
                async: true,
                dataType: "json",
                contentType: 'application/json',
                crossDomain: true,
                headers: {
                    'Authorization': `Bearer ${oAuthToken}`,
                    'Content-Type': 'application/json;charset=utf-8',
                    'Accept': 'application/json;charset=utf-8',
                }
            })
                .then(res => {
                    resolve(res.data);
                })
                .catch(function (error) {
                    reject(error.response);
                });
        });
    }

    checkRoute() {
        const _this = this;
        var url_string = window.location.href;
        var url = new URL(url_string);
        var emailid = url.searchParams.get("emailid");
        if (emailid !== null) {
            sessionStorage.setItem('emailid', emailid)
            _this.setState({
                emailid: emailid,
                progressPercentage: 30,
            }, () => this.loadDataEmail());
        } else {
            this.wrongRoute();
        }
    }

    wrongRoute() {
        const {t} = this.props;
        this.setState({errorText: t('loading.invalid-url'), loading: false});
    }

    loadDataEmail() {
        const {t} = this.props;
        const _this = this;
        this.getOauthEmailToken()
            .then(oauthResponse => {
                sessionStorage.setItem('oauthTokenB64', oauthResponse)
                _this.setState({oAuthToken: oauthResponse, progressPercentage: 50});
                this.checkLanguage()
                    .then(langResponse => {

                        i18n.changeLanguage(langResponse.Language);
                        _this.setState({progressPercentage: 60, pocessText: t('loading.process-initializing')});
                        this.postEvidence()
                            .then(() => {
                                _this.setState({progressPercentage: 70, pocessText: t('loading.process-sending-sms')});
                        
                                sessionStorage.setItem('SendDocToRecipient', langResponse.SendDocToRecipient);

                                if(!langResponse.PhoneNumber){
                                    _this.setState({customSMS: true, redirect: true, loading: false});
                                    return;
                                }

                                sessionStorage.setItem('PhoneNumber', langResponse.PhoneNumber);

                                this.sendSMS()
                                    .then(smsResponse => {
                                        _this.setState({progressPercentage: 100, pocessText: t('loading.process-redirecting')});
                                        if (smsResponse === 204) {
                                            /**SMS sent, redirect to otp input */
                                            setTimeout(() => {
                                                _this.setState({redirect: true, loading: false});
                                            }, 2000);
                                        }
                                    })
                                    .catch(function (error) {
                                        _this.setState({
                                            errorText: error.data,
                                            loading: false
                                        });
                                    });
                            })
                            .catch(function (error) {
                                _this.setState({
                                    errorText: error.data,
                                    loading: false
                                });
                            });
                    })
                    .catch(function (error) {
                        /**Document status is delivered */
                        if (error.status === 409) {
                            _this.setState({
                                errorText: t('loading.error-delivered'),
                                loading: false
                            });
                        }
                        /**Document has been removed or expired */
                        else if (error.status === 404) {
                            _this.setState({
                                errorText: t('loading.error-removed'),
                                loading: false
                            });
                        }
                        else {
                            _this.setState({
                                errorText: error.data,
                                loading: false
                            });
                        }
                    });
            })
            .catch(function (error) {
                /**Not active notification */
                if (error.status === 401) {
                    _this.setState({
                        errorText: t('loading.error-not-active'),
                        loading: false
                    });
                }
                else {
                    _this.setState({
                        errorText: error.data,
                        loading: false
                    });
                }
            });
    }

    getOauthEmailToken() {
        const {emailid} = this.state;
        return new Promise(function (resolve, reject) {
            var authorizationHeader = btoa(environments.clientId + ':' + environments.clientSecret);
            axios({
                method: 'post',
                url: environments.vidUrlApiMng + '/oauth/token',
                data: {
                    username: emailid,
                    password: '',
                    grant_type: 'password',
                    scope: 'edelivery\\email'
                },
                async: true,
                dataType: "json",
                contentType: 'application/json',
                crossDomain: true,
                headers: {'Authorization': 'Basic ' + authorizationHeader}
            })
                .then(res => {
                    resolve(res.data.access_token);
                })
                .catch(function (error) {
                    reject(error.response);
                });
        });
    }

    postEvidence() {
        const {emailid, clientIp, ipData, oAuthToken} = this.state;
        var evidenceLocation = (ipData.latitude + ipData.longitude).toString;
        var browserStringified = JSON.stringify(browser);

        return new Promise(function (resolve, reject) {
            axios({
                method: 'post',
                url: environments.vidUrlApiPriv + '/edeliveries/' + emailid + '/evidences',
                data: {
                    EvidenceType: 'EMAIL_CLICKED',
                    EvidenceIP: clientIp,
                    EvidenceLocation: evidenceLocation,
                    From: browserStringified
                },
                async: true,
                dataType: "json",
                contentType: 'application/json',
                crossDomain: true,
                headers: {
                    'Authorization': `Bearer ${oAuthToken}`,
                    'Content-Type': 'application/json;charset=utf-8',
                    'Accept': 'application/json;charset=utf-8',
                }
            })
                .then(res => {
                    sessionStorage.setItem('isTokenValid', true)
                    resolve(res.data);
                })
                .catch(function (error) {
                    sessionStorage.setItem('isTokenValid', false)
                    reject(error.response);
                });
        });
    }

    sendSMS() {
        const {emailid, oAuthToken} = this.state;

        return new Promise(function (resolve, reject) {
            axios({
                method: 'put',
                url: environments.vidUrlApiPriv + '/edeliveries/' + emailid + '/otpsms',
                data: {
                    "PhoneNumber": sessionStorage.getItem("PhoneNumber")
                },
                async: true,
                dataType: "json",
                contentType: 'application/json',
                crossDomain: true,
                headers: {
                    'Authorization': `Bearer ${oAuthToken}`,
                    'Content-Type': 'application/json;charset=utf-8',
                    'Accept': 'application/json;charset=utf-8',
                }
            })
                .then(res => {
                    resolve(res.status);
                })
                .catch(function (error) {
                    reject(error.response);
                });
        });
    }

    render() {
        const {t} = this.props;
        const {errorText, redirect, progressPercentage, pocessText, loading, customSMS} = this.state;

        if (redirect) {
            return <Redirect to={{
                pathname: customSMS ? '/custom-sms' : '/sms'
            }}
            />;
        }

        return (
            <div>
                <Header/>
                <div className="loading-container">
                    <div className="loading-text-container">
                        <div className="welcome-text-container">
                            {/*<h1 className="welcome-text">{t('loading.welcome-1')}</h1>*/}
                            <h1 className="welcome-text">{t('loading.welcome-2')}</h1>
                        </div>
                        <div className="content-error">
                            <p className="error-text">{errorText}</p>
                        </div>
                        {loading &&
                        <div className="content-process">
                            <div>
                                <Spinner animation="border" role="status" size="sm">
                                    <span className="sr-only">{t('general.loading')}</span>
                                </Spinner>
                                <p className="process-text">{pocessText}</p>
                            </div>
                            <ProgressBar variant="success" now={progressPercentage} label={`${progressPercentage}%`} srOnly/>
                        </div>
                        }
                    </div>
                </div>
            </div>
        );
    }

    componentDidMount() {
        const _this = this;
        const {t} = this.props;
        sessionStorage.clear();
        (async () => {
            const clientIp = await publicIp.v4();
            const ipData = await ipLocation(clientIp);
            _this.setState({
                clientIp: clientIp,
                ipData: ipData,
                progressPercentage: 10,
                pocessText: '...',
                loading: true
            }, () => this.checkRoute());
        })();
    }
}

export default withTranslation()(Loading);
