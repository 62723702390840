import React, {Component} from 'react';
import './SendSMS.css';
import {withTranslation} from 'react-i18next';
import {Button, Form, InputGroup, Spinner, Toast} from "react-bootstrap";
import axios from "axios";
import {Redirect} from 'react-router-dom'
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import OtpIcon from './../../assets/images/otp-icon.svg';
import environments from '../../config/environments';

class SendSMS extends Component {
    constructor(props) {
        super(props);
        this.state = {
            oAuthToken: sessionStorage.getItem('oauthTokenB64'),
            emailid: sessionStorage.getItem('emailid'),
            phoneNumber: sessionStorage.getItem('PhoneNumber'),
            otpInput: '',
            redirect: false,
            validatingOTP: false,
            showError: false,
            errorData: '',
            errorType: '',
            SMSresent: false,
            redirectToLoading: false,
            sessionExpired: false,
            otpError: false
        };
    }

    validateOTP() {
        const {emailid, oAuthToken, otpInput} = this.state;
        const _this = this;
        _this.setState({validatingOTP: true});

        axios({
            method: 'put',
            url: environments.vidUrlApiPriv + '/edeliveries/' + emailid + '/otpcode',
            data: {
                OTP: otpInput
            },
            async: true,
            dataType: "json",
            contentType: 'application/json',
            crossDomain: true,
            headers: {
                'Authorization': `Bearer ${oAuthToken}`,
                'Content-Type': 'application/json;charset=utf-8',
                'Accept': 'application/json;charset=utf-8',
            }
        })
            .then(res => {
                _this.setState({redirect: true, validatingOTP: false});
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    _this.setState({sessionExpired: true});
                } else {
                    _this.setState({
                        otpError: true,
                        validatingOTP: false
                    });
                }
            });
    }

    resendSMS() {
        const {emailid, oAuthToken, phoneNumber} = this.state;
        const _this = this;

        axios({
            method: 'put',
            url: environments.vidUrlApiPriv + '/edeliveries/' + emailid + '/otpsms',
            data: {
                "PhoneNumber": phoneNumber
            },
            async: true,
            dataType: "json",
            contentType: 'application/json',
            crossDomain: true,
            headers: {
                'Authorization': `Bearer ${oAuthToken}`,
                'Content-Type': 'application/json;charset=utf-8',
                'Accept': 'application/json;charset=utf-8',
            }
        })
            .then(res => {
                _this.setState({SMSresent: true});
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    _this.setState({sessionExpired: true});
                } else {
                    _this.setState({
                        errorType: error.response.status,
                        errorData: error.response.data,
                        showError: true
                    });
                }
            });
    }

    redirectToLoading(){
        this.setState({redirectToLoading: true})
    }

    render() {
        const {t} = this.props;
        const {redirect, redirectToLoading, validatingOTP, SMSresent, showError, errorType, errorData, sessionExpired, phoneNumber, otpError} = this.state;

        if (redirect) {
            return <Redirect to={{
                pathname: '/documents'
            }}
            />;
        }

        if (redirectToLoading) {
            return <Redirect to={{
                pathname: '/'
            }}
            />;
        }

        return (
            <div>
                <Header/>
                <div className="send-sms-container">
                    {SMSresent &&
                    <Toast
                        onClose={() => this.setState({SMSresent: false})}
                        show={SMSresent}
                        delay={3000}
                        autohide
                        style={{
                            position: 'absolute',
                            left: 0,
                            right: 0,
                            top: 0,
                            margin: '0 auto',
                            backgroundColor: 'rgba(255,255,255,1)',
                            border: 'none',
                            zIndex: 15
                        }}
                    >
                        <Toast.Header
                            closeButton={false}
                        >
                            <strong className="mr-auto">{t('general.notification')}</strong>
                        </Toast.Header>
                        <Toast.Body>{t('sms.otp-resent')}</Toast.Body>
                    </Toast>
                    }
                    {showError &&
                    <Toast
                        onClose={() => this.setState({showError: false})}
                        show={showError}
                        animation={true}
                        style={{
                            position: 'absolute',
                            left: 0,
                            right: 0,
                            top: 0,
                            margin: '0 auto',
                            backgroundColor: 'rgba(255,255,255,1)',
                            border: 'none',
                            zIndex: 15
                        }}
                    >
                        <Toast.Header
                            closeButton={true}
                        >
                            <strong className="mr-auto">{t('general.error')} {errorType}</strong>
                        </Toast.Header>
                        <Toast.Body>{errorData}</Toast.Body>
                    </Toast>
                    }
                    {sessionExpired &&
                    <div className="toast-container">
                        <Toast
                            show={sessionExpired}
                            style={{
                                position: 'absolute',
                                left: 0,
                                right: 0,
                                margin: '0 auto',
                                backgroundColor: 'rgba(255,255,255,1)',
                                border: 'none',
                                zIndex: 10
                            }}
                        >
                            <Toast.Header
                                closeButton={false}
                            >
                                <strong className="mr-auto">{t('general.notification')}</strong>
                            </Toast.Header>
                            <Toast.Body>
                                <p>{t('general.session-expired')}</p>
                                <Button
                                    type="button"
                                    className="btn btn-primary"
                                    id="sign-all-docs"
                                    onClick={() => this.redirectToLoading()}
                                >
                                    {t('general.accept')}
                                </Button>
                            </Toast.Body>
                        </Toast>
                    </div>
                    }
                    <div className="send-sms-content">
                        <div className="send-sms-content-left">
                            <div className="send-sms-content-left-text">
                                <div className="flex-container">
                                    <img src={OtpIcon} className="otp-icon" alt="otp-icon" />
                                    <h1 className="content-header-title">{t('sms.title')}</h1>
                                </div>
                                <p className="content-middle-1">{t('sms.step-1')}</p>
                                <p className="content-middle-2">({phoneNumber})</p>
                                <p className="content-middle-3">{t('sms.step-2')}</p>
                                <InputGroup className="mb-3 margin-top">
                                    <InputGroup.Prepend>
                                        <InputGroup.Text
                                            id="inputGroup-sizing-default">{t('sms.otp')}</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control
                                        required
                                        type="password"
                                        aria-label="Default"
                                        aria-describedby="inputGroup-sizing-default"
                                        autoFocus={true}
                                        value={this.state.otpInput}
                                        onChange={e => this.setState({otpInput: e.target.value, otpError: false})}
                                        isInvalid={otpError}
                                    />
                                </InputGroup>
                                {otpError &&
                                    <p style={{color: "#ff5757", marginBottom: 0}}>{t('sms.otp-invalid')}</p>
                                }
                                <div className="margin-top">
                                    {validatingOTP &&
                                    <Spinner animation="border" role="status" className="spinner-centered">
                                        <span className="sr-only">{t('general.loading')}</span>
                                    </Spinner>
                                    }
                                    {!validatingOTP &&
                                    <Button
                                        type="button"
                                        className="btn btn-primary send-sms-button action-button"
                                        onClick={() => this.validateOTP()}
                                        disabled={this.state.otpInput.length < 5}
                                    >
                                        {t('sms.view-document')}
                                    </Button>
                                    }
                                </div>
                                <div className="content-bottom">
                                    <p className="content-bottom-text">{t('sms.no-otp')}</p>
                                    <Button
                                        type="button"
                                        className="btn reject action-button-small"
                                        onClick={() => this.resendSMS()}
                                        disabled={validatingOTP}
                                    >
                                        {t('sms.new-otp')}
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <div className="send-sms-content-right"> </div>
                    </div>
                </div>
                <Footer/>
            </div>
        );
    }

    componentDidMount() {

    }
}

export default withTranslation()(SendSMS);
