import React, {Component} from 'react';
import './Footer.css';
import {withTranslation} from 'react-i18next';



class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            footerColor: '#434342'
        };
    }

    render() {
        const {footerColor} = this.state;
        return (
            <div className="footer-container">
                <p className="footer-text" style={{color: footerColor}}>Copyright © 2020 ValidatedID,SL</p>
            </div>
        );
    }

    componentDidMount() {
        var pathName = window.location.pathname;
        this.setState({footerColor: pathName === '/sms' ? '#434342' : '#ffffff'})
    }

}

export default withTranslation()(Footer);