/**PRO environment variables */
// /*
 const environments = {
    vidUrlApiMng: 'https://vidsignercloudmgmt.validatedid.com/api',
    vidUrlApiPriv: 'https://fdgoihnerw.validatedid.com/api',
    clientId: 'edelivery',
    clientSecret: '3fHB9dwkT43d3Li'
};
// */

/**PRE environment variables */
/* 
const environments = {
    vidUrlApiMng: 'https://pre-vidsignercloudmgmt.validatedid.com/api',
    vidUrlApiPriv: 'https://pre-fdgoihnerw.validatedid.com/api',
    clientId: 'edeliverypre',
    clientSecret: 'k3eT92hMdh48',
};
*/

export default environments;
